<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#365F91;mso-themecolor:accent1;
mso-themeshade:191">{{$t('Doctors')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#365F91;mso-themecolor:accent1;
mso-themeshade:191">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-left:2.0in;text-indent:.5in"><b><span lang="EN" style="font-size:14.0pt;
line-height:115%;color:#00B050;mso-themecolor:accent1;mso-themeshade:191">{{$t('Headcompetition')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>
<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#365F91;mso-themecolor:accent1;
mso-themeshade:191">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#365F91">{{$t('Bringexpertise')}}&nbsp;&nbsp;&nbsp; <o:p></o:p></span></b></p>

<p class="MsoNormal" style="margin-left:2.0in;text-indent:.5in"><b><span lang="EN" style="font-size:14.0pt;
line-height:115%;color:#365F91;mso-themecolor:accent1;mso-themeshade:191">&nbsp;</span></b></p>

<p class="MsoNormal"><b>
  <span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050;margin-left:2.0in;text-indent:.5in">{{$t('Ehealth')}}<o:p>
    </o:p></span></b></p>


<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Joining')}}<span style="background:#980000"><o:p></o:p></span></span></p>

<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";text-indent:.5in;'>
  <span style="font-size:16px;line-height:115%;color:#1F497D;">  &nbsp;{{$t('phase1')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";text-indent:.5in;'>
  <span style="font-size:16px;line-height:115%;color:#1F497D;">{{$t('phase3')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";text-indent:.5in;'>
  <span style="font-size:16px;line-height:115%;color:#1F497D;">{{$t('phase2')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";text-indent:.5in;'>
  <span style="font-size:16px;line-height:115%;color:#1F497D;">{{$t('phase4')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";text-indent:.5in;'>
  <span style="font-size:16px;line-height:115%;color:#1F497D;">{{$t('phase5')}}</span>
  </p>


<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%;color:#244061;mso-themecolor:accent1;
mso-themeshade:128">&nbsp;</span></b></p>


<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('RegNow')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN"><a href="https://www.doclinia.com/pages/register/1"><b><span style="font-size:12.0pt;line-height:115%;color:#00B050">https://www.doclinia.com/pages/register/1</span></b></a></span><b><span lang="EN" style="font-size:12.0pt;
line-height:115%;color:#00B050"><o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <o:p></o:p></span></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:15.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('HaveQuestion')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#00B050">{{$t('ContactUs')}} </span><span lang="EN"><a href="mailto:info@doclinia.com"><span style="font-size:12.0pt;line-height:115%;
color:#00B050">info@doclinia.com</span></a></span><span lang="EN" style="font-size:12.0pt;line-height:115%;color:#00B050"><o:p></o:p></span></p>

<span lang="EN" style="font-size:11.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
mso-fareast-font-family:Arial;color:#1F497D;mso-themecolor:text2;mso-ansi-language:
EN;mso-fareast-language:EN-US;mso-bidi-language:AR-SA"><br clear="all" style="mso-special-character:line-break;page-break-before:always">
</span>

<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('Hospitals')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050">{{$t('ListYourHospital')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:14.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('WillContactPatient')}}<o:p></o:p></span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span lang="EN" style="font-size:14.0pt;line-height:115%">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">
  {{$t('WhyDoclinia')}}<o:p></o:p></span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;text-indent:.25in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">{{$t('DocInfo1')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;text-indent:.25in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">{{$t('DocInfo2')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;text-indent:.25in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">{{$t('DocInfo3')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;text-indent:.25in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">{{$t('DocInfo4')}}</span></p>
<!-- <p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">Easy registration and fully automated booking process&nbsp;</span></p> -->
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;text-indent:.25in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">{{$t('DocInfo5')}}</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";margin-left:1.0in;text-indent:.25in;'>
  <span style="font-size:16px;line-height:115%;color:#244061;">{{$t('DocInfo6')}}</span></p>
<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>
<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#4F81BD;mso-themecolor:accent1">{{$t('Signinto')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN"><a href="https://www.doclinia.com/pages/register"><b><span style="font-size:12.0pt;line-height:115%;color:#00B050">https://www.doclinia.com/pages/register</span></b></a></span><b><span lang="EN" style="font-size:12.0pt;
line-height:115%;color:#00B050"><o:p></o:p></span></b></p>

<span lang="EN" style="font-size:11.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
mso-fareast-font-family:Arial;mso-ansi-language:EN;mso-fareast-language:EN-US;
mso-bidi-language:AR-SA"><br clear="all" style="mso-special-character:line-break;
page-break-before:always">
</span>


<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('Strategicalliances')}} <o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%;color:#00B050">{{$t('Beapart')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:14.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2"> {{$t('Part1')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Part2')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Part3')}}<o:p></o:p></span></p>
 <br/>
<p>{{$t('Part4')}}</p>
<p>{{$t('Part5')}}</p>
<p>{{$t('Part6')}}</p>
<p>{{$t('Part7')}}</p>
<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('OurTarget')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('ContactUs')}}</span><span lang="EN"><a href="mailto:info@doclinia.com"><span style="font-size:12.0pt;line-height:115%;
color:#00B050">&nbsp;info@doclinia.com</span></a></span><span lang="EN" style="font-size:12.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">
{{$t('LetUs')}}<o:p></o:p></span></p>

<span lang="EN" style="font-size:11.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
mso-fareast-font-family:Arial;color:#1F497D;mso-themecolor:text2;mso-ansi-language:
EN;mso-fareast-language:EN-US;mso-bidi-language:AR-SA"><br clear="all" style="mso-special-character:line-break;page-break-before:always">
</span>



<p class="MsoNormal"><b><span lang="EN" style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('JoinTeam')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#00B050">{{$t('P1')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P2')}} <o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P3')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P4')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>
<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P5')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('SendUSCV')}} </span><span lang="EN"><a href="mailto:info@doclinia.com"><span style="font-size:12.0pt;line-height:115%;
color:#00B050">info@doclinia.com</span></a></span><span lang="EN" style="font-size:12.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">
{{$t('ToBePart')}}<o:p></o:p></span></p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


